<template>
  <window-content v-if="loading" class="financeiro-window">
    <div class="full-width flex items-center justify-center full-height">
      <sl-loading/>
    </div>
  </window-content>
  <window-content v-else class="financeiro-window" id="container-printer">
    <div class="w-m-header">
      <div>
        <h2 v-if="registro.id">Relatório Dinâmico ID {{ registro.id }} ({{ registro.code }})</h2>
        <h2 v-else>Novo Relatório Dinâmico</h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close hidden-print">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content erp-s-field-bold">
        <e-row mr>
          <e-col style="max-width: 100px; min-width: 100px">
            <erp-s-field label="ID">
              <span v-if="registro.id">#{{ registro.id }}</span>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Nome">
              <erp-input v-model="registro.nome"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 250px">
            <erp-s-field label="Código">
              <erp-input v-model="registro.code"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 250px">
            <erp-s-field label="Entidade">
              <erp-input v-model="registro.entidade"/>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>

      <div v-if="registro.status === 0" class="body-content erp-s-field-bold erp-s-field-m-b" style="background-color: yellow">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Alterações aguardando aprovação</legend>
          </e-col>
          <e-col style="max-width: 30px">
            <div @click="minMax('dataSqlAnalise')" class="cursor-pointer">
              <i v-if="minMaxValues.dataSqlAnalise === true" class="fa fa-minus" />
              <i v-else class="fa fa-plus" />
            </div>
          </e-col>
        </e-row>
        <e-row v-if="minMaxValues.dataSqlAnalise" style="border-radius: 10px">
          <div class="print-wrap"
               style="border: #f2f2f2 1px dotted; background-color: #FFFFFF; width: 100%; max-width: 98%; margin: 20px auto; border-radius: 5px; padding: 12px">
            <textarea style="width: 100%; height: 500px; background-color: #fdfdb4" class="sl-textarea" readonly v-model="registro.dataSqlAnalise"></textarea>
          </div>
        </e-row>
      </div>

      <div class="body-content erp-s-field-bold erp-s-field-m-b">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Conteúdo do Relatório</legend>
          </e-col>
        </e-row>
        <e-row style="background-color: #333333; border-radius: 10px">
          <div class="print-wrap"
               style="border: #f2f2f2 1px dotted; background-color: #FFFFFF; width: 100%; max-width: 98%; margin: 20px auto; border-radius: 5px; padding: 12px">
            <textarea style="width: 100%; height: 800px" class="sl-textarea" v-model="registro.dataSql"></textarea>
          </div>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions hidden-print">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Fechar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save" icon="save" label="Salvar" no-caps
               :color="'positive'"
               class="b-radius-3px no-border-radius-right__"/>
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import {find, create, update} from "@/domain/relatorios/services"
import {VMoney} from "v-money"
import SlLoading from "components/layout/components/Loading.vue"

const mock = {
  id: null,
  code: null,
  nome: null,
  entidade: null,
  dataSql: null,
  dataSqlAnalise: null,
  parametros: null,
}

export default {
  name: 'RelatorioDinamicoWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  directives: {money: VMoney},
  components: {
    ErpInput,
    ErpSField,
    SlLoading,
    ERow,
    ECol,
    UTooltip,
    WindowContent,
  },
  props: ['id', 'options', 'router'],
  data() {
    return {
      loading: true,
      registro: JSON.parse(JSON.stringify(mock)),
      minMaxValues: {
        dataSqlAnalise: true
      }
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.loading = false
    }
  },
  watch: {},
  computed: {},
  methods: {
    load() {
      this.loading = true
      find(this.id || this.registro.id)
          .then(response => {
            this.registro = response.data
            this.$nextTick(() => {
              setTimeout(() => {
                this.loading = false
              }, 1)
            })
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    minMax (v) {
      this.minMaxValues[v] = !this.minMaxValues[v]
    },
    save() {
      let method = this.id || this.registro.id ? update : create
      const data = JSON.parse(JSON.stringify(this.registro))
      this.loading = true
      method(data)
          .then(response => {
            this.registro = response.data
            this.$nextTick(() => {
              this.loading = false
              if (!this.id && this.registro.id) {
                this.load()
              }
              this.dg()
              this.$uloc.window.emit(this.$root.wid, 'updated', this.props)
            })
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
